.contactpag__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    text-decoration: none;
    line-height: 90px;
}

.contactpag__content p {
    line-height: 34px;
}

.contactpag__content a,
.contactpag__content a:active,
.contactpag__content a:focus {
    text-decoration: none;
    background: #ff0;
}