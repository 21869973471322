@font-face {
    font-family: 'FuturaLT';
    src: url('../fonts/FuturaLT-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FuturaLT';
    src: url('../fonts/FuturaLT-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

body {
    -webkit-font-smoothing: antialiased;
}