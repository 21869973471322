header {
    position: fixed;
    top: 20px;
    left: 3%;
    z-index: 999;
}

header li {
    display: inline-block;
    margin-right: 10px;
}

header a {
    text-decoration: none;
}

header a:hover {}

header active,
li.active.menu-item {
    background: #ff0;
}