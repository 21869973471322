section.content__wrapper {
    position: relative;
    max-width: 100%;
    min-height: 1800px;
    padding: 0 3%;
}

.block {
    width: 100%;
    border: 10px black solid;
    background: white;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.drag {
    position: absolute;
    cursor: move;
}

.block__header {
    position: relative;
    width: 100%;
    height: 50px;
    border-bottom: 10px black solid;
    background: white;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.block__controls {
    position: relative;
    display: inline-block;
    width: 121px;
    height: 100%;
    border-right: 10px black solid;
    background: white;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.block__controls--circle {
    position: absolute;
    top: 12px;
    width: 18px;
    height: 18px;
    border-radius: 100px;
    background: black;
}

.circle--1 {
    left: 18px;
}

.circle--2 {
    left: 46px;
}

.circle--3 {
    left: 74px;
}

.block__date {
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 18px;
    text-align: right;
    background: #ff0;
}

.block__content {
    overflow: hidden;
}

.block__content h2,
.block__content h4 {
    margin-top: 50px;
    padding: 0px 30px;
    margin-bottom: 50px;
}

.block__content h3 {
    margin-top: 75px;
    padding: 0px 30px;
    margin-bottom: 75px;
}

.block__content img,
.block__content iframe {
    margin-top: -1px;
    margin-bottom: -6px;
}

// Block positioning homepage
#block-1 {
    width: 100%;
    max-width: 550px;
    top: 0px;
    left: 10%;
}

#block-2 {
    width: 100%;
    max-width: 450px;
    top: 250px;
    right: 25%;
}

#block-3 {
    width: 100%;
    max-width: 350px;
    top: 800px;
    left: 5%;
}

#block-4 {
    width: 100%;
    max-width: 520px;
    top: 1200px;
    left: 15%;
}

#block__experiment1 {
    width: 100%;
    max-width: 500px;
    height: 500px;
    top: 1000px;
    right: 10%;
    background: white;
    overflow: hidden;
}

#block__experiment1 canvas {
    padding-top: 10px;
    margin-bottom: -10px;
}

.madeby {
    position: relative;
    margin-bottom: 12px;
    float: right;
    padding-right: 3%;
}

.madeby a {
    background: #ff0;
}

// Mobile -> free roam converts to list
@media all and (max-width: 1000px) and (min-width: 0px) {
    .drag {
        position: relative;
        cursor: auto;
    }
    #block__experiment1,
    #block-1,
    #block-2,
    #block-3,
    #block-4 {
        top: inherit;
        left: inherit;
        right: inherit;
    }
}