.post__wrapper {
    padding: 0 3%;
}

.post__container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    margin-top: calc(50vh - 200px);
}

.post__container li {
    margin-bottom: 16vh;
}

@media all and (max-width: 1000px) and (min-width: 0px) {
    .post__container li {
        margin-bottom: 10vh;
    }
}