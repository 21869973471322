body {
    width: 100%;
    height: 100%;
    font-family: 'FuturaLT', 'helvetica', sans-serif;
    font-size: 22px;
    background: #e6e6e6;
    overflow-x: hidden;
}

img {
    width: 100%;
}

a,
a:hover {
    color: #000;
    text-decoration: none;
}

// Normal size
p,
h4 {
    font-size: 22px;
    font-weight: normal;
    word-break: keep-all;
    line-height: 28px;
}

p a:link {
    text-decoration: underline;
}

p a:hover {}

// Bigger size
h1,
h2 {
    font-size: 28px;
    font-weight: bold;
    line-height: 36px;
}

// Quote size
h3 {
    font-size: 40px;
    font-weight: bold;
    line-height: 55px;
}

.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail {
    max-width: 100%;
    height: auto;
}

em {}

strong {}